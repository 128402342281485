import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from '../routes/routes.utils';

const blockedRoutes = [
  routes.error.path,
  routes.timeout.path,
  routes.retry.path,
  routes.mobileVerificationComplete.path,
  routes.desktopVerificationCompleted.path,
  routes.clickToContinue.path,
  routes.sdkCameraError.path,
  routes.verificationInProgress.path,
  routes.onboardingInstructions.path,
  routes.qr.path,
  routes.sdkProcessing.path,
];

export const useNavigationControl = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBlockedRoute = useRef(false);

  useEffect(() => {
    isBlockedRoute.current = blockedRoutes.some((route) => location.pathname.includes(route));

    const handlePopState = (event: PopStateEvent) => {
      if (isBlockedRoute.current) {
        event.preventDefault();
        navigate(location.pathname, { replace: true });
      }
    };

    if (isBlockedRoute.current) {
      window.history.pushState(null, '', location.pathname);
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, location]);
};
