import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { mobileDetection } from '../../hooks/device-detection';
import { FlowActions, useFlowContext } from '../../hooks/verification-flow';
import { routes } from '../../routes/routes.utils';
import { handleNewSession } from '../../utils/monitoring/init-ui-monitoring';
import { captureException } from '../../utils/monitoring/sentry';
import { useNavigationControl } from '../../utils/useNavigationControl';

import './main-layout.component.scss';

interface MainLayoutProps {
  className?: string;
}

const MainLayout: React.FunctionComponent<MainLayoutProps> = function ({ className }) {
  const { state, dispatch } = useFlowContext();
  const { isMobile } = mobileDetection();
  const { sid = '', startToken = '' } = useParams();
  const navigate = useNavigate();
  useNavigationControl();

  useEffect(() => {
    handleNewSession(state.sessionId);

    // if we are not in mobile and we already have session id and our url session id is empty. than redirect to qr page.
    if (isMobile === false && startToken) {
      navigate(`/${startToken}/${routes.qr.path}`);
    }
  }, [state]);

  useEffect(() => {
    if (!sid || !!state.sessionId) {
      return;
    }
    if (state.appSettings.isSDKEnabled) {
      return;
    }
    if (state.status === 'capturing' && state.missingImages) {
      const currentStep = state.missingImages?.[0];
      let step: FlowActions;
      let path;
      if (currentStep === 'document_front') {
        step = 'DOCUMENT_FRONT';
        path = routes.scanDocumentFront.path;
      } else if (currentStep === 'document_back') {
        step = 'DOCUMENT_BACK';
        path = routes.scanDocumentBack.path;
      } else if (currentStep === 'selfie') {
        step = 'SELFIE';
        path = routes.selfieImage.path;
      } else {
        step = 'ERROR';
        path = routes.error.path;
        const errorMessage = `Unknown step ${currentStep}`;
        captureException(new Error(errorMessage), {
          ...state,
          missingImages: state.missingImages,
          currentStep: currentStep,
        });
      }
      dispatch({
        ...state,
        sessionId: sid,
        currentStep: step,
      });
      navigate(`/session/${sid}/${path}`);
    }
  }, [state.status]);

  return (
    <section className={`main-layout ${className ?? ''}`}>
      <Outlet />
    </section>
  );
};

export default MainLayout;
