const ListOnboardingSvg = (): React.ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="list-icon svg-primary-color"
    >
      <g clipPath="url(#clip0_3720_1502)">
        <path
          opacity="0.4"
          d="M14.9375 7.125L25.875 7.125L25.875 18.0625L21.1875 18.0625L21.1875 21.1875L25.875 21.1875C27.5986 21.1875 29 19.7861 29 18.0625L29 7.125C29 5.40137 27.5986 4 25.875 4L14.9375 4C13.2139 4 11.8125 5.40137 11.8125 7.125L11.8125 11.8125L14.9375 11.8125L14.9375 7.125Z"
          fill="#4597FF"
        />
        <path
          d="M21.1875 14.9375C21.1875 13.2139 19.7861 11.8125 18.0625 11.8125L7.125 11.8125C5.40137 11.8125 4 13.2139 4 14.9375L4 25.875C4 27.5986 5.40137 29 7.125 29L18.0625 29C19.7861 29 21.1875 27.5986 21.1875 25.875L21.1875 14.9375Z"
          fill="#4597FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3720_1502">
          <rect width="25" height="25" fill="white" transform="translate(29 29) rotate(180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ListOnboardingSvg;
